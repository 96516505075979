@import './main.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
    margin: 0.5rem 0.75rem;
  }
}

html {
  font-size: 20px; // Base line for rem units

  @media (max-width: $mobile-breakpoint) {
    font-size: 16px; // Base line for rem units
  }

  @media (max-width: $large-tablet-breakpoint) and (min-width: $mobile-breakpoint) {
    font-size: 18px; // Base line for rem units
  }

  @media (max-width: $small-desktop-breakpoint) and (min-width: $mobile-breakpoint) {
    font-size: 19px; // Base line for rem units
  }

  @media (min-width: $wide-screen-breakpoint) {
    font-size: 22px; // Base line for rem units
  }
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  // Angular material section
  .mat-checkbox-checkmark {
    border-radius: 2px;
  }

  .mat-button-disabled .mat-icon {
    color: $primary-color;
  }

  // End angular material section

  // Shared styles

  .app-navbar {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    color: $primary-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 100px;
    position: fixed;
    top: 0;
    z-index: 100;
    height: 72px;

    @media (max-width: $mobile-breakpoint) {
      padding: 12px 24px;
    }

    .navbar-img {
      width: 115px;
      margin-top: 10px;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: $mobile-breakpoint) {
        width: 85px;
        padding-top: 15px;
        margin-top: 0px;
      }
    }
  }

  ::-moz-selection {
    /* Code for Firefox */
    color: $contrast-color;
    background: $light-color;
  }

  ::selection {
    color: $contrast-color;
    background: $light-color;
  }

  /* Force colors*/
  .light-background {
    background: $main-background-color !important;
  }

  .grey-background {
    background: $alt-background-color !important;
  }

  .primary-background {
    background: $primary-color !important;
  }

  .secondary-background {
    background: $secondary-color !important;
  }

  .dark-background {
    background: $contrast-color !important;
  }

  .warning-background {
    background: $warning-color !important;
  }

  .accent-color {
    color: $accent-color !important;
  }

  .accent-color-hover:hover {
    color: $accent-color !important;
  }

  .contrast-color {
    color: $contrast-color !important;
  }

  .contrast-color-hover:hover {
    color: $contrast-color !important;
  }

  .contrast-light-color {
    color: $contrast-light-color !important;
  }

  .contrast-light-color-hover:hover {
    color: $contrast-light-color !important;
  }

  .contrast-dark-color {
    color: $contrast-dark-color !important;
  }

  .contrast-dark-color-hover:hover {
    color: $contrast-dark-color !important;
  }

  .primary-color {
    color: $primary-color !important;
  }

  .primary-color-hover:hover {
    color: $primary-color !important;
  }

  .secondary-color {
    color: $secondary-color !important;
  }

  .secondary-color-hover:hover {
    color: $secondary-color !important;
  }

  .light-color {
    color: $light-color !important;
  }

  .light-color-hover:hove {
    color: $light-color !important;
  }

  .error-color {
    color: $error-color !important;
  }

  .warning-color {
    color: $warning-color !important;
  }

  /* End force colors*/

  /* Texts */
  .o8-h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.3;
    color: $contrast-color;
    margin-top: 80px;

    @media (max-width: $small-desktop-breakpoint) {
      font-size: 2.2rem;
      margin-top: 50px;
    }
  }

  .o8-h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.65rem;
    line-height: 1.3;
    color: $contrast-color;

    @media (max-width: $small-desktop-breakpoint) {
      line-height: 1.2;
    }
  }

  .o8-h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.36;
    color: $contrast-color;
  }

  .o8-subhead {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4;
    color: $contrast-color;
  }

  .o8-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.19;
    color: $text-color;
  }

  .o8-a {
    text-decoration: none;
    color: $contrast-color;
    cursor: pointer;
    &:hover {
      color: $secondary-color;
    }
  }

  .o8-medium-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 1.1;

    @media (max-width: $small-desktop-breakpoint) {
      font-size: 0.85rem;
    }

    @media (max-width: $mobile-breakpoint) {
      font-size: 0.9rem;
    }
  }

  .o8-large-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.2;
    color: $text-color;
  }

  .o8-small-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.7rem;
    line-height: 1;
    color: $text-color;

    @media (max-width: $small-desktop-breakpoint) {
      font-size: 0.75rem;
    }

    @media (max-width: $mobile-breakpoint) {
      font-size: 0.8rem;
    }
  }

  .o8-list-feature-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.28;
    color: $text-color;
  }

  .bold-text {
    font-weight: bold;
  }

  .regular-weight-text {
    font-weight: 500;
  }

  .light-weight-text {
    font-weight: 300;
  }

  .italic {
    font-style: italic;
  }

  .centered-text {
    text-align: center;
  }

  /* End texts*/

  /* Miscallenous*/
  .with-headline-separator {
    &::after {
      width: 50px;
      height: 7px;
      background-color: $primary-color;
      content: '  ';
      display: block;
      margin: 20px auto;

      @media (max-width: $small-desktop-breakpoint) {
        margin: 15px auto 0;
        height: 5px;
      }
    }
  }

  .full-height {
    height: 100% !important;
  }

  .full-width {
    width: 100% !important;
  }

  .half-width {
    width: 50% !important;
  }

  .width-80-percent {
    width: 80%;
  }

  .width-200px {
    width: 200px;
  }

  .min-width-200px {
    min-width: 200px;
  }

  .width-150px {
    width: 150px;
  }

  .min-width-150px {
    min-width: 150px;
  }

  .height-40 {
    height: 40px;
  }

  .height-30 {
    height: 30px;
  }

  .height-20 {
    height: 20px;
  }

  .with-o8-scrollbar {
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
  }

  .border-radius-10 {
    border-radius: 10px;
  }

  .border-radius-25 {
    border-radius: 25px;
  }

  .border-radius-top-10 {
    border-radius: 10px 10px 0 0;
  }

  .border-radius-bottom-10 {
    border-radius: 0 0 10px 10px;
  }

  .o8-hyperlink {
    color: $primary-color;

    &:hover {
      color: $secondary-color;
    }
  }

  .width-80-percent {
    width: 80%;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .opacity-0-5 {
    opacity: 0.5;
  }

  .pointer {
    cursor: pointer;
  }

  /* End Miscallenous*/

  /* Layout */
  .display-block {
    display: block;
  }

  .centered-horizontal {
    margin-left: auto;
    margin-right: auto;
  }

  .centered-vertical {
    margin-top: auto;
    margin-bottom: auto;
  }

  .flex-column-on-mobile {
    @media (max-width: $mobile-breakpoint) {
      display: flex;
      flex-direction: column;
    }
  }

  .flex-column-on-mobile-and-tablet {
    @media (max-width: $small-desktop-breakpoint) {
      display: flex;
      flex-direction: column;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .flex-grow-2 {
    flex-grow: 2;
  }

  .align-items-center {
    align-items: center;
  }

  /* End Layout*/

  /* Margin / Padding*/

  .box-sizing-border {
    box-sizing: border-box;
  }

  .margin-auto {
    margin: auto;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .margin-right-auto {
    margin-right: auto;
  }

  .margin-top-auto {
    margin-top: auto;
  }

  .margin-bottom-auto {
    margin-bottom: auto;
  }

  .margin-top-5px {
    margin-top: 5px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .margin-top-1x {
    margin-top: 1rem !important;
  }

  .margin-top-1x-mobile {
    @media (max-width: $mobile-breakpoint) {
      margin-top: 1rem !important;
    }
  }

  .margin-top-2x {
    margin-top: 2rem !important;
  }

  .margin-top-0-5x {
    margin-top: 0.5rem !important;
  }

  .margin-top-0-5x-mobile {
    @media (max-width: $mobile-breakpoint) {
      margin-top: 0.5rem !important;
    }
  }

  .margin-right-1x {
    margin-right: 1rem !important;
  }

  .margin-right-2x {
    margin-right: 2rem !important;
  }

  .margin-right-0-5x {
    margin-right: 0.5rem !important;
  }

  .margin-right-5px {
    margin-right: 5px !important;
  }

  .margin-left-5px {
    margin-left: 5px !important;
  }

  .margin-left-1x {
    margin-left: 1rem !important;
  }

  .margin-left-2x {
    margin-left: 2rem !important;
  }

  .margin-left-0-5x {
    margin-left: 0.5rem !important;
  }

  .margin-bottom-1x {
    margin-bottom: 1rem !important;
  }

  .margin-bottom-2x {
    margin-bottom: 2rem !important;
  }

  .margin-bottom-0-5x {
    margin-bottom: 0.5rem !important;
  }

  .margin-bottom-5px {
    margin-bottom: 5px;
  }

  .padding-top-2px {
    padding-top: 2px;
  }

  .padding-10px {
    padding: 10px;
  }

  .padding-top-10px {
    padding-top: 10px;
  }

  .padding-20px {
    padding: 20px;
  }

  .padding-vertical-0-5x {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }

  .padding-horizontal-0-5x {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .padding-horizontal-1x {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /* End Margin / Padding */

  /* Inputs */

  @layer components {
    .o8-base-input {
      @apply pl-2 py-1 pr-2 bg-gray-50 border border-gray-300 text-black-500 text-sm rounded-full block focus-visible:outline-1 focus-visible:outline-black-100;
    }
    .o8-input-with-left-icon {
      @apply pl-8 py-1 pr-2 bg-gray-50 border border-gray-300 text-black-500 text-sm rounded-full block focus-visible:outline-1 focus-visible:outline-black-100;
    }
    .o8-input-with-right-icon {
      @apply pl-2 py-1 pr-8 bg-gray-50 border border-gray-300 text-black-500 text-sm rounded-full block focus-visible:outline-1 focus-visible:outline-black-100;
    }

    .o8-primary-btn {
      @apply items-center font-medium bg-green-500 rounded-full text-white hover:bg-black-300 hover:border-black-300 disabled:bg-green-300;
    }

    .o8-accent-btn {
      @apply items-center border-solid border font-medium border-green-500 rounded-full bg-white text-green-500 hover:bg-black-300 hover:border-black-300 hover:text-white;
    }

    .o8-danger-btn {
      @apply items-center font-medium bg-red-500 rounded-full text-white hover:bg-black-300 hover:border-black-300 disabled:bg-red-300;
    }

    .o8-accent-danger-btn {
      @apply items-center border-solid border font-medium border-red-500 rounded-full bg-white text-red-500  hover:border-red-700 hover:text-red-700 disabled:border-red-300 disabled:text-red-300;
    }

    .o8-big-btn {
      @apply px-6 py-2 text-sm;
    }

    .o8-small-btn {
      @apply text-2xs px-4;
    }

    .o8-medium-btn {
      @apply text-xs px-5 py-1;
    }
  }

  .o8-input {
    height: 45px !important;
    border-radius: 500px;
    outline: none !important;
    // border: solid 0.1px $light-color;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9rem;
    font-family: 'Roboto';
    color: $contrast-color !important;
    background-color: $main-background-color !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 12px 24px;
    /* Auto Layout */
    &::placeholder {
      font-style: italic;
      font-size: 0.9rem;
    }

    &.with-border {
      border: solid 1px $contrast-color;
    }

    &.squared-input {
      border-radius: 8px;
    }
  }

  .o8-dropdown {
    &::placeholder {
      font-style: italic;
      font-size: 0.9rem;
    }
  }

  .dropdown-placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  *.dark-input {
    color: $accent-color !important;
    background: $contrast-light-color !important;
    border: none;

    &::placeholder {
      color: $accent-color;
      font-family: 'Roboto' !important;
      font-style: normal !important;
    }
  }

  *.o8-input-error {
    border: 1px solid $error-color !important;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $error-color !important;
      opacity: 1;
      /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $error-color;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $error-color;
    }

    & .dropdown-placeholder {
      color: $error-color !important;
    }
  }

  .o8-error-message {
    margin-top: 5px;
    font-size: 0.6rem;
    font-family: 'Roboto';
    padding: 8px;
    background-color: $error-color-light;
    color: $error-color;
    border-radius: 8px;
    font-style: italic;
    text-align: left;

    @media (max-width: $mobile-breakpoint) {
      font-size: 0.7rem;
    }
  }

  .o8-warn-message {
    margin-top: 5px;
    font-size: 0.6rem;
    font-family: 'Roboto';
    padding: 8px;
    background-color: $warning-color-dark;
    color: $accent-color;
    border-radius: 8px;
    font-style: italic;
    text-align: left;

    @media (max-width: $mobile-breakpoint) {
      font-size: 0.7rem;
    }
  }

  /* End Inputs */

  /* Buttons */

  .o8-regular-button-radius {
    border-radius: 20px;
  }

  .submitted-spinner {
    margin: auto;

    .mat-mdc-progress-spinner circle,
    .mat-mdc-spinner circle {
      stroke: white;
    }
  }

  .submitted-btn {
    .mat-button-wrapper {
      display: flex;
    }

    @media (max-width: $small-desktop-breakpoint) {
      padding: 7px;
    }
  }

  /* End buttons*/

  /* Show / hide */
  .show-only-on-mobile {
    @media (min-width: $mobile-breakpoint) {
      display: none !important;
    }
  }

  .hide-only-on-mobile {
    @media (max-width: $mobile-breakpoint) {
      display: none !important;
    }
  }

  .show-only-on-desktop-and-higher {
    @media (max-width: $small-desktop-breakpoint) {
      display: none !important;
    }
  }

  .show-only-on-standard-tablet-and-higher {
    @media (max-width: $standard-tablet-breakpoint) {
      display: none !important;
    }
  }

  .show-only-on-lower-than-standard-tablet {
    @media (min-width: $standard-tablet-breakpoint) {
      display: none !important;
    }
  }

  .hide-only-on-desktop-and-higher {
    @media (min-width: $small-desktop-breakpoint) {
      display: none !important;
    }
  }

  .show-only-on-large-tablet-and-higher {
    @media (max-width: $large-tablet-breakpoint) {
      display: none !important;
    }
  }

  .hide-only-on-large-tablet-and-higher {
    @media (min-width: $large-tablet-breakpoint) {
      display: none !important;
    }
  }

  /*  End show hide */

  .app-section {
    display: flex;
    flex-direction: column;
    padding: 0 15px 30px 15px;
  }

  .origin8-line {
    border: 1px solid $primary-color;
  }

  .extra-50-margin-bottom {
    margin-bottom: 50px;
  }

  // End shared styles section
}
